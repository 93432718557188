import React, { useState } from "react";
import * as service from "../services";
import { startLoading, stopLoading, validateEmail, randomString } from "../components/Util";
import { useNavigate } from "react-router-dom";
import lab_logo from "../../src/assets/images/labiconics-logo.png";

function Forgot(props) {
  const navigate = useNavigate();
  const [username, setUserName] = useState("");
  const [errors, setErrors] = useState({});
  const [successMessage, setMessage] = useState();

  const validate = () => {
    setErrors({});
    let isValid = true;
    if (!username || username.length <= 0) {
        setErrors({ loginFailure: 'Please enter email address.' });
        isValid = false;
    }

    if (username && username.length > 0 && !validateEmail(username)) {
      setErrors({ loginFailure: 'Please enter valid email address.' });
      isValid = false;
    }

   console.log(username);

    return isValid;
};
const handleKeyDown = e => {
    if (e.key === 'Enter') {
      handleSubmit();
    }
}

  const handleSubmit = () => {
    if(validate()){
    startLoading();
    let temp_pass = randomString(15);
    service.forgot_password({ username, password: temp_pass }, (res) => {
      // console.log("Res Stuatus: ", res.status);
      if (res && res.status) {
        if (res.status === "SUCCESS") setMessage(res.msg); setErrors({ loginFailure: "" })
        if (res.status === "ERROR") setErrors({ loginFailure: res.msg })
        if (!username || username.length <= 0) setErrors({ loginFailure: 'Please enter Email address.' })
        stopLoading();
      }
    });
  }
  };

  return (
    <>
      <div className="login lims-eln-login">
        <div className="lm-login-inner">
          <div className="form-signin">
          <div className="logos"><img src={lab_logo} alt="Lab Iconics LIMS" height="30"/></div>
            <form className="login-form">
              {!successMessage &&
                <>
                  <div className="field">
                    <label className="label">User Name</label>
                    <div className="control has-icons-left">
                      <input type="email" className="input" id="floatingInput"
                        placeholder="name@example.com" value={username || ""}
                        onChange={(e) => setUserName(e.target.value)}
                        onKeyDown={handleKeyDown}
                        required
                        autoFocus
                      />
                      <span className="icon is-small is-left">
                        <i className="mdi mdi-account"></i>
                      </span>
                    </div>
                    <p className="help has-text-light"><small>{errors.loginFailure}</small></p>
                  </div>
                  <div className="buttons is-right">
                    <button type="button" className="button is-info btn-standard" onClick={() => navigate('/login')}><small>Cancel</small></button>
                    <button type="button" className="button is-info btn-standard"
                      onClick={() => { handleSubmit(); }}><small>Submit</small>
                    </button>
                  </div>
                </>
              }

              {successMessage &&
                <> <p className="" style={{ color: "white", paddingBottom: "20px" }}>We have sent you an email with a temporary password.</p>
                  <div className="buttons is-centered">
                    <button className="button is-info btn-standard"
                      style={{ color: "#ffffff" }} type="button"
                      onClick={() => navigate("/login")}>OK
                    </button>
                  </div>
                </>
              }
            </form>
          </div>
        </div>
      </div>
    </>
  );
}

export default Forgot;
