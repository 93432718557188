import CustomerGrid from "./pages/Customers/CustomerGrid";
import ViewCustomer from "./pages/Customers/ViewCustomer";
import CreateCustomer from "./pages/Customers/CreateCustomer";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import Login from "./pages/Login";
import Forgot from "./pages/Forgot";
import ResetPassword from "./pages/ResetPassword";
import { AuthProvider } from "./context/auth";
import { ProtectedRoute } from "./pages/ProtectedRoute";
import UsersGrid from "./pages/Users/UsersGrid";
import UserDetails from "./pages/Users/UserDetails";
import "react-datepicker/dist/react-datepicker.css";
import Layout from "./layout/Layout";
import EditUser from "./pages/Users/EditUser";

function App() {
  return (
    <BrowserRouter>
      <AuthProvider>
        <Routes>
          <Route element={<Layout />}>
            <Route path="/" exact element={<Navigate to="/dashboard" />} />
            <Route path="dashboard">
              <Route index element={<ProtectedRoute><CustomerGrid /></ProtectedRoute>} />
              <Route path="viewcustomer/:id" element={<ProtectedRoute><ViewCustomer /></ProtectedRoute>} />
              <Route path="createcustomer/:id" element={<ProtectedRoute><CreateCustomer /></ProtectedRoute>} />
            </Route>
            <Route path="user">
              <Route index element={<ProtectedRoute><UsersGrid /></ProtectedRoute>} />
              <Route path="userdetail/:id" element={<ProtectedRoute><UserDetails /></ProtectedRoute>} />
              <Route path="edit/:id" element={<ProtectedRoute><EditUser /></ProtectedRoute>} />
            </Route>
          </Route>
          <Route path="/login" element={<Login />} />
          <Route path="/forgot" element={<Forgot />} />
          <Route path="/reset-password" element={<ResetPassword />} />
        </Routes>
      </AuthProvider>
    </BrowserRouter>
  );
}

export default App;
