import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import * as service from "../../services";
import { startLoading, stopLoading, validateEmail, randomString } from "../../components/Util";
import { useNavigate } from "react-router-dom";
import Select from 'react-select';

function UserDetails(props) {
  const { id } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [recipe, setRecipe] = useState({ id, status_id: "NEW" });
  const [userId, setUserId] = useState(id);
  const [errors, setErrors] = useState({});
  const [passUpMessage, setpassUpMessage] = useState();
  const [passNWMessage, setpassNWMessage] = useState();
  const [passMessage, setPassMessage] = useState();
  const [lookups, setLookups] = useState({});
  const [validEmail, setValidEmail] = useState()

  useEffect(() => {
    startLoading();
    let p1 = new Promise((resolve, reject) => {
      if (id !== '0') {
        service.get_users({ id }, res => {
          if (res.data) {
            setRecipe({ ...res.data[0] });
            resolve("");
          }
        });
      } else resolve("");
    });

    let p2 = new Promise((resolve, reject) => {
      service.get_common_lookups({}, res => {
        setLookups(res);
        resolve("");
      });
    });

    Promise.all([p1, p2])
      .then(values => {
        setLoading(false)
        stopLoading();
      })
      .catch(error => {
        console.log(error.message);
      });
    //eslint-disable-next-line 
  }, [userId]);

  useEffect(() => {
    if (!loading) {
      console.log("loaded...");
    }
  }, [loading]);

  const handleChange = (prop, value) => {
    setRecipe(recipe => ({ ...recipe, [prop]: value }));
  };

  const validate = () => {
    setErrors({});
    let isValid = true;
    let validation = [
      { field: 'first_name', msg: 'Please enter First Name.' },
      { field: 'last_name', msg: 'Please enter Last Name.' },
      { field: 'email', msg: 'Please enter Email.' },
      { field: 'phone_number', msg: 'Please enter Phone Number.' }
    ];

    if (recipe.phone_number && recipe.phone_number.length < 12) {
      setErrors(errors => ({ ...errors, 'phone_number': 'Phone Number should be 12 digits.' }));
      isValid = false;
    }

    validation.map(item => {
      if (!recipe[item.field] || recipe[item.field].length <= 0) {
        setErrors(errors => ({ ...errors, [item.field]: item.msg }));
        isValid = false;
      }
      return item;
    });
    if (recipe.email && recipe.email.length > 0 && !validateEmail(recipe.email)) {
      setErrors(errors => ({ ...errors, 'email': 'Please enter valid email address.' }));
      isValid = false;
    }
    return isValid;
  };

  const upsert = () => {
    if (validate()) {
      startLoading();
      let payload = JSON.parse(JSON.stringify(recipe));
      if (payload.id === "0") delete payload.id;
      payload.operation_type = id === "0" ? "INSERT" : "UPDATE";
      payload.password = randomString(15);
      payload.is_deleted = payload.status_id === "ACTIVE" ? false : true;
      service.upsert_user(payload, res => {
        if (res.status === 'SUCCESS') {
          if (res.results) setUserId(res.data[0].id);
          if (userId === "0") setpassNWMessage("Record Created.");
          if (userId !== "0") setpassUpMessage("Record Updated.");
        }
        setTimeout(() => {
          setpassNWMessage()
          setpassUpMessage()
        }, 3000);
        stopLoading();
        navigate("/user/userdetail/" + res.data[0].id)
      });
    }
  }
  const checkEmail = () => {
    setValidEmail();
    let payload = JSON.parse(JSON.stringify(recipe));
    // console.log("payload: ", payload);
    service.is_username_valid(payload, res => {
      if (res.data.is_email_valid !== true) setValidEmail("Record with same Email ID already exists.")
    })
  }

  const sendPassword = () => {
    if (validate()) {
      startLoading();
      let payload = JSON.parse(JSON.stringify(recipe));
      payload.operation_type = "RESET_PASSWORD";
      payload.user_id = userId;
      payload.password = randomString(15);
      service.send_password_by_id(payload, res => {
        stopLoading();
        if (res.status === 'SUCCESS') {
          setPassMessage("Password Sent.")
        }
        setTimeout(() => {
          setPassMessage()
        }, 3000);
      })
    }
  }

  let status_lookup = [];
  if (lookups.USER_STATUS) status_lookup = recipe.status_id + '' !== 'NEW' ? lookups.USER_STATUS.filter(item => item.value !== 'NEW') : lookups.USER_STATUS;

  return (
    <>
      <div className="container">
        <br />
        <h5 className="subtitle is-3 mb-2" style={{ display: "inline", verticalAlign: "middle", paddingRight: "35%" }}>
          {userId !== '0' ? 'User Details' : 'Create User'}
        </h5>
        {passNWMessage && <div className="field-label is-normal" style={{ display: "inline", verticalAlign: "middle", }}>
          <b>{passNWMessage}</b>
        </div>}
        <hr />
        <div className="field is-horizontal">
          <div className="field-label is-normal">
            <label className="label">First Name</label>
          </div>
          <div className="field-body">
            <div className="field">
              <div className="control">
                <input type="text" placeholder="Enter Fist Name" className="input" value={recipe.first_name || ''}
                  onChange={(e) => handleChange('first_name', e.target.value)} />
                {errors.first_name && <div className="help is-danger"><small>{errors.first_name}</small></div>}
              </div>
            </div>
          </div>
        </div>
        <div className="field is-horizontal">
          <div className="field-label is-normal">
            <label className="label">Last Name</label>
          </div>
          <div className="field-body">
            <div className="field">
              <div className="control">
                <input type="text" placeholder="Enter Last Name" className="input" value={recipe.last_name || ''}
                  onChange={(e) => handleChange('last_name', e.target.value)} />
                {errors.last_name && <div className="help is-danger"><small>{errors.last_name}</small></div>}
              </div>
            </div>
          </div>
        </div>
        <div className="field is-horizontal">
          <div className="field-label is-normal">
            <label className="label">Email</label>
          </div>
          <div className="field-body">
            <div className="field">
              <div className="control">
                <input type="text" placeholder="Enter Email" className="input" disabled={userId !== '0'} value={recipe.email || ''}
                  onChange={(e) => handleChange('email', e.target.value.toLowerCase())} onBlur={() => { checkEmail() }} />
                {errors.email && <div className="help is-danger"><small>{errors.email}</small></div>}
                {validEmail && <div className="help is-danger"><small>{validEmail}</small></div>}
              </div>
            </div>
          </div>
        </div>
        <div className="field is-horizontal">
          <div className="field-label is-normal">
            <label className="label">Phone Number</label>
          </div>
          <div className="field-body">
            <div className="field">
              <div className="control">
                <input type="text" className="input" placeholder="Enter Phone Number" pattern="[0-9]*" maxLength="12" value={recipe.phone_number || ''}
                  onBlur={(e) => handleChange('phone_number', ((e.target.validity.valid) ? e.target.value : ''))}
                  onChange={(e) => handleChange('phone_number', ((e.target.validity.valid) ? e.target.value : ''))} />
                {errors.phone_number && <div className="help is-danger"><small>{errors.phone_number}</small></div>}
              </div>
            </div>
          </div>
        </div>
        <div className="field is-horizontal">
          <div className="field-label is-normal">
            <label className="label">Status</label>
          </div>
          <div className="field-body">
            <div className="field">
              <div className="control">
                <Select value={(recipe.status_id && status_lookup && status_lookup.find(item => item.value === recipe.status_id + '')) || ''}
                  options={status_lookup || []}
                  isDisabled={userId === '0'}
                  required
                  placeholder="Status"
                  onChange={(e) => { handleChange('status_id', e.value) }}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="field is-horizontal">
          <div className="field-body">
            <div className="field is-grouped is-grouped-right">
              {passUpMessage && <div className="field-label is-normal" style={{ marginRight: '10px' }}>
                <b>{passUpMessage}</b>
              </div>}
              {passMessage && <div className="field-label is-normal" style={{ marginRight: '10px' }}>
                <b>{passMessage}</b>
              </div>}
              <p className="control">
                <button type="button" className="button is-link btn-standard" onClick={e => { navigate(-1) }}>Back</button>
              </p>
              <p className="control">
                <button type="button" disabled={userId === '0'} className="button is-primary btn-standard" onClick={e => { sendPassword() }}>
                  Generate Password
                </button>
              </p>
              <p className="control">
                <button type="button" className="button is-primary btn-standard" onClick={e => { upsert() }} disabled={validEmail}>
                  {userId === '0' ? 'Save' : recipe.is_deleted === true ? "Restore" : "Update"}
                </button>
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default UserDetails;
