import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import * as service from "../../services";
import { startLoading, stopLoading, validateEmail } from "../../components/Util";
import { useNavigate } from "react-router-dom";
import Select from 'react-select';
import DatePicker from "react-datepicker";
import moment from 'moment';

function CreateCustomer(props) {

  const { id } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [recipe, setRecipe] = useState({ id });
  const [customer_license, setCustomerLicense] = useState({
    product: 'LIMS',
    subscription: 'Perpetual',
    modules: ['LIMS_ADMIN_ADMINISTRATION', 'LIMS_MASTER_TEMPLATE_MASTER_DATA', 'LIMS_LDMS']
  });
  const [customer_contact, setCustomerContact] = useState([]);
  const [customer_contacts, setCustomerContacts] = useState([]);
  const [customerId] = useState(id);
  const [errors, setErrors] = useState({});
  const [lookup, setLookups] = useState({});
  const [modules, setModules] = useState([]);
  const [uniqueCode, setUniqueCode] = useState()

  useEffect(() => {
    startLoading();
    let p1 = new Promise((resolve, reject) => {
      if (id !== '0') {
        service.get_customers({ id }, res => {
          if (res.data) {
            setRecipe({ ...res.data[0] });
            setCustomerContacts(res.data[0].customer_contacts || [])
            setCustomerLicense(res.data[0].customer_license || [])
            resolve("");
          }
        });
      } else resolve("");
    });

    let p2 = new Promise((resolve, reject) => {
      service.get_common_lookups({}, res => {
        setLookups(res);
        resolve("");
      });
    });

    let p3 = new Promise((resolve, reject) => {
      service.get_modules_lookups(res => {
        setModules(res.data || []);
        resolve("");
      });
    });

    Promise.all([p1, p2, p3])
      .then(values => {
        setLoading(false)
        stopLoading();
      })
      .catch(error => {
        console.log(error.message);
      });
    //eslint-disable-next-line 
  }, [customerId]);

  useEffect(() => {
    if (!loading) {
      console.log("loaded...");
    }
  }, [loading]);

  const validate = () => {
    setErrors({});
    let isValid = true;
    let validation = [
      { field: 'code', msg: 'Please enter Customer Code.' },
      { field: 'name', msg: 'Please enter Customer Name.' },
      { field: 'address1', msg: 'Please enter Address Line 1.' },
      { field: 'address2', msg: 'Please enter Address Line 2.' },
      { field: 'address3', msg: 'Please enter Address Line 3.' },
      { field: 'city', msg: 'Please enter City.' },
      { field: 'state', msg: 'Please enter State.' },
      { field: 'country_id', msg: 'Please enter Country.' }
    ];

    let licValidation = [
      { field: 'users', msg: 'Please enter No.of User Licenses.' },
      { field: 'product', msg: 'Please select a Product.' },
      { field: 'subscription', msg: 'Please select a Service.' }
    ];

    if (customer_contacts.length === 0) validation.push({ field: "contact", msg: "Please enter Contact Person Details." })
    if (customer_license.subscription === 'SaaS') licValidation.push({ field: 'saas_expiry_date', msg: 'Please select License Expiry Date.' })
    if (customer_license.amc_start_date && (!customer_license.amc_validity_period || !customer_license.amc_validity_period_unit)) {
      licValidation.push({ field: 'amc_validity_period', msg: 'Please select Validity Period.' })
      licValidation.push({ field: 'amc_validity_period_unit', msg: 'Please select Validity Period Unit.' })
    }
    if (customer_license.modules && customer_license.modules.length < 1) validation.push({ field: 'modules', msg: 'Please select Module.' })

    validation.map(item => {
      if (!recipe[item.field] || recipe[item.field].length <= 0) {
        setErrors(errors => ({ ...errors, [item.field]: item.msg }));
        isValid = false;
      }
      return item;
    });

    licValidation.map(item => {
      if (!customer_license[item.field] || customer_license[item.field].length <= 0) {
        setErrors(errors => ({ ...errors, [item.field]: item.msg }));
        isValid = false;
      }
      return item;
    });

    return isValid;
  };

  const contactValidate = () => {
    setErrors({});
    let isValid = true;
    let contactvalidation = [
      { field: 'contact_name', msg: 'Please enter Name.' },
      { field: 'phone', msg: 'Please enter Phone Number.' },
      { field: 'email', msg: 'Please enter valid email address.' },
    ];

    if (customer_contact.phone && customer_contact.phone.length < 12) {
      setErrors(errors => ({ ...errors, 'phone': 'Phone Number should be 12 digits.' }));
      isValid = false;
    }

    if (customer_contact.email && customer_contact.email.length > 0 && !validateEmail(customer_contact.email)) {
      setErrors(errors => ({ ...errors, 'email': 'Please enter valid email address.' }));
      isValid = false;
    }

    contactvalidation.map(item => {
      if (!customer_contact[item.field] || customer_contact[item.field].length <= 0) {
        setErrors(errors => ({ ...errors, [item.field]: item.msg }));
        isValid = false;
      }
      return item;
    });

    return isValid;
  }

  const addValue = () => {
    if (contactValidate()) {
      if (!recipe.id) recipe.id = 0;
      setCustomerContacts([{
        contact_name: customer_contact.contact_name,
        phone: customer_contact.phone,
        email: customer_contact.email,
        operation_type: 'INSERT',
        status_id: 'ACTIVE',
      }, ...customer_contacts])
      customer_contact.contact_name = ""
      customer_contact.phone = ""
      customer_contact.email = ""
      setCustomerContact({ ...customer_contact })
    }
  }

  const upsert = () => {
    if (validate()) {
      startLoading();
      let payload = JSON.parse(JSON.stringify(recipe));
      payload.customer_contacts = customer_contacts;
      payload.customer_license = customer_license;
      payload.status_id = payload.status_id ? payload.status_id : 'ACTIVE';
      payload.is_lic_generated = false;
      if (payload.id === "0") delete payload.id;
      payload.operation_type = id === "0" ? "INSERT" : "UPDATE";
      payload.is_deleted = "false";
      // console.log("Payload: ", payload);
      service.upsert_customer(payload, res => {
        navigate("/dashboard/viewcustomer/" + res.data[0].id)
      });
    }
  }

  const onChangeHandler = (prop, value) => {
    setRecipe(recipe => ({ ...recipe, [prop]: value }));
  };

  const onLicChangeHandler = (prop, value) => {
    if (prop === 'product') customer_license.modules = ['LIMS_ADMIN_ADMINISTRATION', 'LIMS_MASTER_TEMPLATE_MASTER_DATA', 'LIMS_LDMS']
    setCustomerLicense(customer_license => ({ ...customer_license, [prop]: value }));
  };

  const onContractorChangeHandler = (prop, value) => {
    setCustomerContact(customer_contact => ({ ...customer_contact, [prop]: value }));
  };

  useEffect(() => {
    if (customer_license.amc_start_date && customer_license.amc_validity_period && customer_license.amc_validity_period_unit) {
      customer_license.amc_end_date = moment(customer_license.amc_start_date).add(customer_license.amc_validity_period, (customer_license.amc_validity_period_unit.label === 'Year' ? 'Y' : 'M')).utc().toISOString();
      setCustomerLicense({ ...customer_license });
    }
    // eslint-disable-next-line 
  }, [customer_license.amc_start_date, customer_license.amc_validity_period, customer_license.amc_validity_period_unit]);

  const checkUniqueCode = () => {
    setUniqueCode();
    let payload = JSON.parse(JSON.stringify(recipe));
    service.is_unique_code(payload, res => {
      if (res.data.is_unique !== true) setUniqueCode("Record with same Code already exists.");
    })
  }
  const amc_end_date = moment(new Date(customer_license.amc_end_date));
  let updated_end_dt = amc_end_date.format('DD-MMM-YYYY');
  return (
    <>
      <div className="container">
        <br />
        <h5 className="subtitle is-3 mb-2" style={{ display: "inline", verticalAlign: "middle", paddingRight: "35%" }}>
          {customerId !== '0' ? 'Customer Details' : 'Create Customer'}
        </h5>
        <hr />
        <div className="field is-horizontal">
          <div className="field-label is-normal">
            <label className="label">Customer Code</label>
          </div>
          <div className="field-body">
            <div className="field">
              <div className="control">
                <input disabled={(recipe.is_disabled || false) || id !== '0'} className="input" placeholder="Enter Customer Code" maxLength="100" value={recipe.code || ''}
                  onChange={(e) => onChangeHandler('code', e.target.value)}
                  onBlur={() => checkUniqueCode()} />
              </div>
              {errors.code && <p className="help is-danger">{errors.code}</p>}
              {uniqueCode && <p className="help is-danger">{uniqueCode}</p>}
            </div>
          </div>
        </div>
        <div className="field is-horizontal">
          <div className="field-label is-normal">
            <label className="label">Customer Name</label>
          </div>
          <div className="field-body">
            <div className="field">
              <div className="control">
                <input disabled={recipe.is_disabled || false} className="input" placeholder="Enter Customer Name" maxLength="100" value={recipe.name || ''} onChange={(e) => onChangeHandler('name', e.target.value)} />
              </div>
              {errors.name && <p className="help is-danger">{errors.name}</p>}
            </div>
          </div>
        </div>
        <div className="field is-horizontal">
          <div className="field-label is-normal">
            <label className="label">Address Line 1</label>
          </div>
          <div className="field-body">
            <div className="field">
              <div className="control">
                <input disabled={recipe.is_disabled || false} className="input" placeholder="Enter Address Line 1" maxLength="1000" value={recipe.address1 || ''} onChange={(e) => onChangeHandler('address1', e.target.value)} />
              </div>
              {errors.address1 && <p className="help is-danger">{errors.address1}</p>}
            </div>
          </div>
        </div>
        <div className="field is-horizontal">
          <div className="field-label is-normal">
            <label className="label">Address Line 2</label>
          </div>
          <div className="field-body">
            <div className="field">
              <div className="control">
                <input disabled={recipe.is_disabled || false} className="input" placeholder="Enter Address Line 2" maxLength="1000" value={recipe.address2 || ''} onChange={(e) => onChangeHandler('address2', e.target.value)} />
              </div>
              {errors.address2 && <p className="help is-danger">{errors.address2}</p>}
            </div>
          </div>
        </div>
        <div className="field is-horizontal">
          <div className="field-label is-normal">
            <label className="label">Address Line 3</label>
          </div>
          <div className="field-body">
            <div className="field">
              <div className="control">
                <input disabled={recipe.is_disabled || false} className="input" placeholder="Enter Address Line 3" maxLength="1000" value={recipe.address3 || ''} onChange={(e) => onChangeHandler('address3', e.target.value)} />
              </div>
              {errors.address3 && <p className="help is-danger">{errors.address3}</p>}
            </div>
          </div>
        </div>
        <div className="field is-horizontal">
          <div className="field-label is-normal">
            <label className="label">City</label>
          </div>
          <div className="field-body">
            <div className="field is-narrow">
              <div className="control">
                <input disabled={recipe.is_disabled || false} className="input" placeholder="Enter City" maxLength="100" value={recipe.city || ''} onChange={(e) => onChangeHandler('city', e.target.value)} />
              </div>
              {errors.city && <p className="help is-danger">{errors.city}</p>}
            </div>
          </div>
        </div>
        <div className="field is-horizontal">
          <div className="field-label is-normal">
            <label className="label">State</label>
          </div>
          <div className="field-body">
            <div className="field">
              <div className="control">
                <input disabled={recipe.is_disabled || false} className="input" placeholder="Enter State" maxLength="100" value={recipe.state || ''} onChange={(e) => onChangeHandler('state', e.target.value)} />
              </div>
              {errors.state && <p className="help is-danger">{errors.state}</p>}
            </div>
          </div>
        </div>
        <div className="field is-horizontal">
          <div className="field-label is-normal">
            <label className="label">Country</label>
          </div>
          <div className="field-body">
            <div className="field" style={{ maxWidth: "203px" }}>
              <div className="control">
                <Select
                  isDisabled={recipe.is_disabled || false}
                  value={lookup.COUNTRIES && lookup.COUNTRIES.filter(item => parseInt(item.value) === parseInt(recipe.country_id))[0]}
                  options={lookup.COUNTRIES || []}
                  required
                  placeholder="Country"
                  onChange={(e) => { onChangeHandler('country_id', e.value) }}
                />
              </div>
              {errors.country_id && <p className="help is-danger">{errors.country_id}</p>}
            </div>
          </div>
        </div>
        <div className="field is-horizontal">
          <div className="field-label is-normal">
            <label className="label">URL</label>
          </div>
          <div className="field-body">
            <div className="field">
              <div className="control">
                <input disabled={recipe.is_disabled || false} className="input" placeholder="Enter URL" maxLength="100" value={recipe.url || ''} onChange={(e) => onChangeHandler('url', e.target.value)} />
              </div>
            </div>
          </div>
        </div>

        <div className="field is-horizontal">
          <div className="field-label is-normal">
            <label className="label">Contact Person Details</label>
          </div>
          <div className="field-body">
            <span className="control is-expanded " style={{ width: '100%' }}>
              <div className="content is-small">
                <div className="columns is-mobile" style={{ paddingTop: "10px" }}>
                  <div className="column">
                    <div className="field is-horizontal">
                      <div className="field-label is-normal">
                        <label className="label">Name</label>
                      </div>
                      <div className="field-body">
                        <div className="field is-narrow">
                          <div className="control">
                            <input className="input" placeholder="Enter Name" maxLength="100" value={customer_contact.contact_name || ''}
                              onChange={(e) => { onContractorChangeHandler('contact_name', e.target.value) }} />
                          </div>
                          {errors.contact_name && <p className="help is-danger">{errors.contact_name}</p>}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="column">
                    <div className="field is-horizontal">
                      <div className="field-label is-normal">
                        <label className="label">Phone Number</label>
                      </div>
                      <div className="field-body">
                        <div className="field is-narrow">
                          <div className="control">
                            <input className="input" type='text' pattern="[0-9]*" placeholder="Enter Phone Number" maxLength="12" value={customer_contact.phone || ''}
                              onChange={(e) => { onContractorChangeHandler('phone', (e.target.validity.valid) ? e.target.value : '') }} />
                          </div>
                          {errors.phone && <p className="help is-danger">{errors.phone}</p>}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="column">
                    <div className="field is-horizontal">
                      <div className="field-label is-normal">
                        <label className="label">Email</label>
                      </div>
                      <div className="field-body">
                        <div className="field is-narrow">
                          <div className="control">
                            <input className="input" placeholder="Enter Email" maxLength="100" value={customer_contact.email || ''}
                              onChange={(e) => { onContractorChangeHandler('email', e.target.value) }} />
                          </div>
                          {errors.email && <p className="help is-danger">{errors.email}</p>}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="column is-flex-grow-0">
                    <button className="button is-primary btn-standard" type="button" onClick={(e) => { addValue() }} > Add</button>
                  </div>
                </div>
              </div>
            </span>
          </div>
        </div>

        <div className="field is-horizontal">
          <div className="field-label is-normal">
            <label className="label">&nbsp;</label>
          </div>
          <div className="field-body">
            <div className="field">
              <div className="control">
                {customer_contacts && customer_contacts.filter(item => item.status_id === 'ACTIVE').length > 0 ?
                  <table className="table is-striped is-hoverable is-fullwidth">
                    <thead>
                      <tr>
                        <td>Name</td>
                        <td>Phone Number</td>
                        <td>Email</td>
                        <td></td>
                      </tr>
                    </thead>
                    <tbody>
                      {customer_contacts.filter(item => item.status_id === 'ACTIVE').map((item, index) => {
                        return <tr key={index}>
                          <td style={{ width: '30%' }} >{item.contact_name}</td>
                          <td style={{ width: '30%' }} >{item.phone}</td>
                          <td style={{ width: '30%' }}>{item.email}</td>
                          <td style={{ textAlign: 'right', paddingRight: '0' }}><button className="button is-small is-danger"
                            onClick={() => {
                              customer_contacts[index].status_id = 'DISABLED';
                              setCustomerContacts([...customer_contacts])
                            }}
                          >Remove</button></td>
                        </tr>
                      })}
                    </tbody>
                  </table> : <div className="blank-div" style={{ padding: '15px', fontSize: '2em' }}>No records available.</div>}
              </div>
            </div>
          </div>
        </div>

        <div className="field is-horizontal">
          <div className="field-label is-normal">&nbsp; </div>
          <div className="field-body">
            <div className="field is-narrow">
              {errors.contact && <p className="help is-danger">{errors.contact}</p>}
            </div>
          </div>
        </div>

        <h5 className="subtitle is-4 mb-2">Application Configuration</h5><hr />
        <div className="field is-horizontal pt-1 pb-1">
          <div className="field-label">
            <label className="label">Product</label>
          </div>
          <div className="field-body">
            <div className="field is-narrow">
              <label className="checkbox">
                <span className="custom-radio">
                  <input type="radio" name="ptype" checked={customer_license.product === 'LIMS' || false}
                    onChange={(e) => { onLicChangeHandler("product", e.target.checked ? 'LIMS' : undefined) }}
                  /><span className="radiomark"></span>
                </span> Lab Iconics LIMS
              </label>
            </div>
            <div className="field is-narrow">
              <label className="checkbox">
                <span className="custom-radio">
                  <input type="radio" name="ptype" checked={customer_license.product === 'ELN' || false}
                    onChange={(e) => { onLicChangeHandler("product", e.target.checked ? 'ELN' : undefined) }}
                  /><span className="radiomark"></span>
                </span> Lab Iconics ELN
              </label>
            </div>
            <div className="field is-narrow">
              <label className="checkbox">
                <span className="custom-radio">
                  <input type="radio" name="ptype" checked={customer_license.product === 'LIMS-ELN' || false}
                    onChange={(e) => { onLicChangeHandler("product", e.target.checked ? 'LIMS-ELN' : undefined) }}
                  /><span className="radiomark"></span>
                </span> Lab Iconics LIMS-ELN
              </label>
            </div>
          </div>
        </div>
        <div className="field is-horizontal">
          <div className=" field-label is-normal">
          </div>
          <div className="field-body">
            {errors.product && <p className="help is-danger">{errors.product}</p>}
          </div>
        </div>
        <div className="field is-horizontal">
          <div className=" field-label is-normal">
            <label className="label">No.of User Licenses</label>
          </div>
          <div className="field-body">
            <div className="field is-narrow is-flex-basis-50">
              <div className="control">
                <input className="input" placeholder="Enter No.of User Licenses" value={customer_license.users || ''} pattern={"[0-9]*"} onChange={(e) => {
                  onLicChangeHandler("users", (e.target.validity.valid) ? parseInt(e.target.value) : customer_license.users);
                }} />
                {errors.users && <p className="help is-danger">{errors.users}</p>}
              </div>
            </div>
          </div>
        </div>
        <div className="field is-horizontal pt-1 pb-1">
          <div className="field-label">
            <label className="label">Services</label>
          </div>
          <div className="field-body">
            <div className="field is-narrow">
              <label className="checkbox">
                <span className="custom-radio">
                  <input type="radio" name="stype" checked={customer_license.subscription === 'Perpetual' || false}
                    onChange={(e) => { onLicChangeHandler("subscription", e.target.checked ? 'Perpetual' : undefined) }}
                  /><span className="radiomark"></span>
                </span> Perpetual
              </label>
            </div>
            <div className="field is-narrow">
              <label className="checkbox">
                <span className="custom-radio">
                  <input type="radio" name="stype" checked={customer_license.subscription === 'SaaS' || false}
                    onChange={(e) => { onLicChangeHandler("subscription", e.target.checked ? 'SaaS' : undefined) }}
                  /><span className="radiomark"></span>
                </span> SaaS
              </label>
            </div>
          </div>
        </div>
        <div className="field is-horizontal">
          <div className=" field-label is-normal">
          </div>
          <div className="field-body">
            {errors.subscription && <p className="help is-danger">{errors.subscription}</p>}
          </div>
        </div>
        {customer_license.subscription === 'SaaS' && <div className="field is-horizontal">
          <div className="field-label is-normal">
            <label className="label">License Expiry Date</label>
          </div>
          <div className="field-body">
            <div className="field is-narrow">
              <div className="control">
                <DatePicker
                  placeholderText="License Expiry Date"
                  selected={customer_license.saas_expiry_date ? new Date(customer_license.saas_expiry_date) : ''}
                  className="input"
                  onChange={(date) => onLicChangeHandler('saas_expiry_date', date)}
                  dateFormat="dd-MMM-yyyy"
                  showMonthDropdown={true}
                  selectsStart
                  minDate={new Date()}
                />
              </div>
              {errors.saas_expiry_date && <p className="help is-danger">{errors.saas_expiry_date}</p>}
            </div>
          </div>
        </div>}
        <h5 className="subtitle is-4 mb-2">AMC</h5><hr />
        <div className="field is-horizontal">
          <div className="field-label is-normal">
            <label className="label">Start Date</label>
          </div>
          <div className="field-body">
            <div className="field is-narrow">
              <div className="control">
                <DatePicker
                  placeholderText="Start Date"
                  selected={customer_license.amc_start_date ? new Date(customer_license.amc_start_date) : ''}
                  className="input"
                  onChange={(date) => onLicChangeHandler('amc_start_date', date)}
                  dateFormat="dd-MMM-yyyy"
                  showMonthDropdown={true}
                  selectsStart
                  minDate={new Date()}
                />
              </div>
              {errors.amc_start_date && <p className="help is-danger">{errors.amc_start_date}</p>}
            </div>
          </div>
        </div>
        <div className="field is-horizontal">
          <div className="field-label is-normal">
            <label className="label">Validity Period</label>
          </div>
          <div className="field-body">
            <div className="field is-narrow">
              <div className="control">
                <input className="input" placeholder="Enter Validity Period" maxLength="14" pattern={"[0-9]*"}
                  value={customer_license.amc_validity_period || ''}
                  onChange={(e) => {
                    onLicChangeHandler("amc_validity_period", (e.target.validity.valid) ? e.target.value : customer_license.amc_validity_period);
                  }}
                />
                {errors.amc_validity_period && <p className="help is-danger">{errors.amc_validity_period}</p>}
              </div>
            </div>
            <div className="field" style={{ maxWidth: "203px" }}>
              <div className="control">
                <Select value={customer_license.amc_validity_period_unit || ''}
                  options={
                    [
                      { label: 'Month', value: 'Month' },
                      { label: 'Year', value: 'Year' }
                    ]
                  }
                  menuPlacement="auto" required placeholder="Select"
                  onChange={(e) => { onLicChangeHandler('amc_validity_period_unit', e) }} />
                {errors.amc_validity_period_unit && <p className="help is-danger">{errors.amc_validity_period_unit}</p>}
              </div>
            </div>
          </div>
        </div>

        {customer_license.amc_start_date && customer_license.amc_validity_period && customer_license.amc_validity_period_unit && <div className="field is-horizontal">
          <div className="field-label is-normal">
            <label className="label">End Date</label>
          </div>
          <div className="field-body">
            <div className="field is-narrow">
              <div className="control">
                <input className="input" disabled={true} value={updated_end_dt || ''} />
                <input className="input" type="hidden" disabled={true} value={customer_license.amc_end_date || ''} />
              </div>
            </div>
          </div>
        </div>}


        <h5 className="subtitle is-4 mb-2">Modules</h5><hr />
        <div className="columns m-0 is-flex-wrap-wrap">
          {modules && modules.filter(m => customer_license.product === 'LIMS' ? m.is_lims : customer_license.product === 'ELN' ? m.is_eln : m.is_lims || m.is_eln).map((item, index) => {
            return <div key={index} className="column is-one-third ">
              <label className="checkbox">
                <span className="custom-check">
                  <input type="checkbox"
                    checked={customer_license.modules.includes(item.id) || false}
                    disabled={item.id === 'LIMS_ADMIN_ADMINISTRATION' || item.id === 'LIMS_MASTER_TEMPLATE_MASTER_DATA' || item.id === 'LIMS_LDMS' ||
                      (item.id === 'LIMS_SAMPLE_MANAGEMENT'
                        && (customer_license.modules.includes('LIMS_ANALYST_QUALIFICATION') || customer_license.modules.includes('LIMS_RETAIN_SAMPLE')))
                    }
                    onChange={(e) => {
                      let _modules = customer_license.modules || [];
                      if (e.target.checked) {
                        _modules.push(item.id);
                        if (item.id === 'LIMS_ANALYST_QUALIFICATION' || item.id === 'LIMS_RETAIN_SAMPLE')
                          _modules.push('LIMS_SAMPLE_MANAGEMENT');
                      } else _modules = _modules.filter(m => m !== item.id)
                      customer_license.modules = _modules;
                      setCustomerLicense({ ...customer_license })
                    }} />
                  <span className="checkmark"></span>
                </span> {item.menu_name}
              </label>
            </div>
          })}
        </div>

        <div className="field is-horizontal">
          <div className="field-body">
            <div className="field is-grouped is-grouped-right">
              <p className="control">
                <button type="button" className="button is-link btn-standard" onClick={e => { navigate(-1) }}>Back</button>
              </p>
              <p className="control">
                <button type="button" className="button is-primary btn-standard" disabled={uniqueCode} onClick={e => { upsert() }}>
                  {customerId === '0' ? 'Submit' : "Update"}
                </button>
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default CreateCustomer;
