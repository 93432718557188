import React from 'react'
import { NavLink } from 'react-router-dom';

function Sidebar() {
    return (
        <div id="sidebar" className="sidebar lims-eln">
            <div id="sidebar-menu">
                <ul className="metismenu list-unstyled">
                    <li className='nav-child-gp'>
                        <NavLink to="dashboard">
                            <div className="nav-link">
                                <span className="icon is-left"><i className="mdi mdi-account-details-outline"></i></span><span className="sidebar-label">Customers</span>
                            </div>
                        </NavLink>
                    </li>
                    <li className='nav-child-gp'>
                        <NavLink to="user">
                            <div className="nav-link">
                                <span className="icon is-left"><i className="mdi mdi-account"></i></span><span className="sidebar-label">Users</span>
                            </div>
                        </NavLink>
                    </li>
                </ul>
            </div>
        </div>
    )
}

export default Sidebar