//import logo from "../assets/images/chc-logo.svg";
import React, { useState, useRef } from 'react';
import { useAuth } from "../context/auth";
import * as service from "../services";
import { useNavigate } from "react-router-dom";
import lab_logo from "../../src/assets/images/labiconics-logo.png";

function Login(props) {
    const navigate = useNavigate();
    const usernameElement = useRef(null);
    const passwordElement = useRef(null);
    const [username, setUserName] = useState('');
    const [password, setPassword] = useState('');
    const [passwordShown, setPasswordShown] = useState(false);
    const [errors, setErrors] = useState({})
    const { login, logout } = useAuth();

    React.useEffect(() => {
        setUserName(usernameElement.current.value || '');
        setPassword(passwordElement.current.value || '');
    }, [usernameElement, passwordElement])

    const validate = () => {
        setErrors({});
        let isValid = true;
        if (!username || username.length <= 0) {
            setErrors({ loginFailure: 'Please enter Email Address.' });
            isValid = false;
        }

        if (username && (!password || password.length <= 0)) {
            setErrors({ loginFailure: 'Please enter Password.' });
            isValid = false;
        }

        return isValid;
    };

    const handleKeyDown = e => {
        if (e.key === 'Enter') {
            handleLoginSubmit();
        }
    }

    const handleLoginSubmit = () => {
        if (validate()) {
            service.login({ username, password }, (res) => {
                if (res && res.status) {
                    if (res.status === "ERROR") {
                        setErrors({ loginFailure: res.msg });
                    } else {
                        sessionStorage.setItem('token', JSON.stringify(res.data));
                        service.introspect(user_res => {
                            let user = user_res.data;
                            if (user.password_reset === true) {
                                navigate("/reset-password", { state: username });
                            } else {
                                service.authorizations(auth_res => {
                                    if (user) user.auths = auth_res.data || [];
                                    if (user.auths && user.auths.length > 0) {
                                        login(user);
                                    } else {
                                        logout();
                                        setErrors({ loginFailure: 'Unauthorised Access.' });
                                    }

                                });
                            }
                        });
                    }
                }
            });
        }
    }

    const togglePassword = () => {
        setPasswordShown(!passwordShown)
    }

    return (

        <div className="login lims-eln-login">
            <div className="lm-login-inner">
                <div className="form-signin">
                    <div className="logos"><img src={lab_logo} alt="Lab Iconics LIMS" height="30"/></div>
                    <form className="login-form">
                        <p className="help has-text-light"><small>{errors.loginFailure}</small></p>
                        <div className="field">
                            <label className="label">User Name</label>
                            <div className="control has-icons-left">
                                <input ref={usernameElement} autoComplete="username" type="email" className="input" id="floatingInput" placeholder="name@example.com"
                                    value={username || ''}
                                    onChange={(e) => setUserName(e.target.value)}
                                    required
                                    autoFocus
                                />
                                <span className="icon is-small is-left">
                                    <i className="mdi mdi-account"></i>
                                </span>
                            </div>
                        </div>

                        <div className="field">
                            <label className="label">Password</label>
                            <div className="control has-icons-left has-icons-right">
                                <input ref={passwordElement} autoComplete="current-password" type={passwordShown ? "text" : "password"} className="input" id="floatingPassword" placeholder="Password"
                                    value={password || ''}
                                    onKeyDown={handleKeyDown}
                                    onChange={(e) => setPassword(e.target.value)}
                                    required
                                />
                                <span className="icon is-small is-left">
                                    <i className="mdi mdi-key"></i>
                                </span>
                                <span className="icon is-large is-right is-clickable" style={{ color: "#000000" }}
                                    onClick={() => { togglePassword() }}>
                                    <i className={`mdi ${!passwordShown ? 'mdi-eye' : 'mdi-eye-off'}`} style={{ color: "#363535" }}></i>
                                </span>
                            </div>
                        </div>

                        <div className="columns">
                            <div className="column is-left pt-4">
                                <button className="button is-ghost is-shadowless has-text-grey-lighter is-left pl-0" onClick={() => navigate('/forgot')}>Forgot Password </button>
                            </div>
                            <div className="column is-right  pt-4">
                                <button type="button" className="button is-info btn-standard" style={{ float: "right" }} onClick={() => { handleLoginSubmit() }} autoFocus={true}><small>Sign in</small></button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>

    );
}

export default Login;
