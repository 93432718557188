import { download, get, post } from "./service";

export const get_modules_lookups = (onDone) => {
    get("/api/customers/modules_lookup", (res) => {
        onDone(res);
    });
};

export const get_customers = (payload, onDone) => {
    post("/api/customers/grid", payload, (res) => {
        onDone(res);
    });
};

export const upsert_customer = (payload, onDone) => {
    post("/api/customers/upsert", payload, (res) => {
        onDone(res);
    });
};

export const is_unique_code = (payload, onDone) => {
    post("/api/customers/is_unique_code", payload, (res) => {
        onDone(res);
    });
};

export const generate_lic = (payload, onDone) => {
    post("/api/customers/generate-lic", payload, (res) => {
        onDone(res);
    });
};

export const download_lic = (cust_id, fileName, payload, onDone) => {
    download(encodeURI("/api/customers/download-lic/" + cust_id + "/" + fileName), payload, (res) => {
        serveFile(fileName, res);
        onDone();
    });
};

let serveFile = (fileName, res) => {
    const url = window.URL.createObjectURL(new Blob([res]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
};
