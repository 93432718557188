import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import * as service from "../../services";
import { startLoading, stopLoading, randomString } from "../../components/Util";
import { useNavigate } from "react-router-dom";
import moment from "moment";

function UserDetails(props) {
  const { id } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [recipe, setRecipe] = useState({ id });
  const [userId] = useState(id);
  const [passMessage, setPassMessage] = useState("Once password is send you will receive email with temporary password.");
  const [isupdated, setisupdated] = useState(false);

  useEffect(() => {
    startLoading();
    let p1 = new Promise((resolve, reject) => {
      if (id !== '0') {
        service.get_users({ id }, res => {
          if (res.data) {
            setRecipe({ ...res.data[0] });
            resolve("");
          }
        });
      } else resolve("");
    });

    Promise.all([p1])
      .then(values => {
        setLoading(false)
        stopLoading();
      })
      .catch(error => {
        console.log(error.message);
      });
    //eslint-disable-next-line 
  }, [isupdated]);

  useEffect(() => {
    if (!loading) {
      console.log("loaded...");
    }
  }, [loading]);

  const sendPassword = () => {
    startLoading();
    let payload = JSON.parse(JSON.stringify(recipe));
    payload.operation_type = "RESET_PASSWORD";
    payload.user_id = userId;
    payload.password = randomString(15);
    service.send_password_by_id(payload, res => {
      stopLoading();
      if (res.status === 'SUCCESS') {
        setPassMessage("Password Sent.")
      }
    })
  }

  const UserStatusUpdate = () => {
    setisupdated(false)
    recipe.is_deleted = recipe.is_deleted ? false : true;
    recipe.status_id = recipe.status_id === 'DISABLED' ? 'ACTIVE' : 'DISABLED'
    recipe.operation_type = "UPDATE";
    recipe.last_key = null;
    startLoading();
    service.upsert_user({ id: id, ...recipe }, res => {
      setisupdated(true);
      stopLoading();
    })
  }

  const created_at = moment(new Date(recipe.created_at_ms));
  let created_at_dt = created_at.format('DD-MMM-YYYY hh:mm A');
  const updated_at = moment(new Date(recipe.updated_at_ms));
  let updated_at_dt = updated_at.format('DD-MMM-YYYY hh:mm A');
  return (
    <>
      <div className="container">
        <br />
        <h5 className="subtitle is-3 mb-2">
          User Details
        </h5>
        <div className="columns">
          <div className="column mt-4">
            <div className="columns">
              <div className="column">
                <article className="message is-light">
                  <div className="message-header">User
                    <div className="tags mb-0 is-capitalized">
                      <span className="tag mb-0 mr-0" style={{ background: "none" }}>Current Status: </span>
                      <span className={`tag mb-0 mr-0 p-0 is-radiusless ${recipe.is_deleted === false ? 'is-primary' : 'is-danger'}`} style={{ height: "12px", width: "12px" }}></span>
                      <span className="tag mb-0 pl-1 is-uppercase" style={{ background: "none" }}>{recipe.status_id}</span>
                      <div className="buttons mb-0">
                        <button className={`button is-small mb-0 ${recipe.status_id === "DISABLED" || recipe.status_id === "INACTIVE" ? 'is-success' : 'is-outlined is-danger'}`} type="button" style={{ height: "2.2em" }} onClick={() => { UserStatusUpdate() }} >{recipe.status_id === "DISABLED" || recipe.status_id === "INACTIVE" ? "Enable" : "Disable"}</button>
                        {recipe.is_deleted === false && <button className="button is-outlined is-small mb-0 is-info" style={{ height: "2.2em" }}>
                          <span className="icon is-tiny"> <i className="mdi mdi-square-edit-outline line-height-normal"></i> </span>
                          <span onClick={() => { navigate("/user/edit/" + recipe.id) }}>Edit</span>
                        </button>}
                      </div>
                    </div>
                  </div>
                  <div className="message-body p-0">
                    <table className="table is-striped is-hoverable is-fullwidth" style={{ border: "1px solid rgb(236, 236, 236)" }}>
                      <tbody>
                        <tr>
                          <td style={{ width: "30%" }}>First Name:</td>
                          <td><b>{recipe.first_name}</b></td>
                        </tr>
                        <tr>
                          <td style={{ width: "30%" }}>Last Name:</td>
                          <td><b>{recipe.last_name}</b></td>
                        </tr>
                        <tr>
                          <td style={{ width: "30%" }}>Email Id:</td>
                          <td><b>{recipe.email}</b></td>
                        </tr>
                        <tr>
                          <td style={{ width: "30%" }}>Phone Number:</td>
                          <td><b>{recipe.phone_number}</b></td>
                        </tr>
                        <tr>
                          <td>Created by</td>
                          <td><b>{recipe.created_by_name}</b></td>
                        </tr>
                        <tr>
                          <td>Created On</td>
                          <td><b>{created_at_dt}</b></td>
                        </tr>
                        {recipe.updated_by_name && <tr>
                          <td>Last Updated by</td>
                          <td><b>{recipe.updated_by_name}</b></td>
                        </tr>}
                        {recipe.updated_at_dt && <tr>
                          <td>Last Updated on</td>
                          <td><b>{updated_at_dt}</b></td>
                        </tr>}
                      </tbody>
                    </table>
                  </div>
                </article>
              </div>
            </div>
            <div className="columns">
              <div className="column">
                <article className="message is-info">
                  <div className="message-header">Password Reset </div>
                  <div className="message-body">
                    <div className="content">
                      <p>{passMessage}</p>
                      <div className="control">
                        <button className="button is-info btn-standard" type="button" disabled={userId === '0' || recipe.is_deleted} onClick={e => { sendPassword() }}>Send Password</button>
                      </div>
                    </div>
                  </div>
                </article>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
export default UserDetails;
