import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import * as service from "../../services";
import { startLoading, stopLoading } from "../../components/Util";
import { useNavigate } from "react-router-dom";
import Select from 'react-select';
import DatePicker from "react-datepicker";
import moment from 'moment';

function ViewCustomer(props) {

  const { id } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [recipe, setRecipe] = useState({ id });
  const [readOnly] = useState({});
  const [modules, setModules] = useState([]);
  const [isupdated, setisupdated] = useState(false);

  useEffect(() => {
    startLoading();
    let p1 = new Promise((resolve, reject) => {
      if (id !== '0') {
        service.get_customers({ id }, res => {
          if (res.data) {
            setRecipe({ ...res.data[0] });
            resolve("");
          }
        });
      } else resolve("");
    });

    let p2 = new Promise((resolve, reject) => {
      service.get_modules_lookups(res => {
        setModules(res.data || []);
        resolve("");
      });
    });
    Promise.all([p1, p2])
      .then(values => {
        setLoading(false)
        stopLoading();
      })
      .catch(error => {
        console.log(error.message);
      });
    //eslint-disable-next-line 
  }, [isupdated]);

  useEffect(() => {
    if (!loading) {
      console.log("loaded...");
    }
  }, [loading]);

  const CustomerStatusUpdate = () => {
    setisupdated(false)
    recipe.status_id = recipe.status_id === 'DISABLED' ? 'ACTIVE' : 'DISABLED'
    recipe.operation_type = "UPDATE";
    recipe.last_key = null;
    startLoading();
    service.upsert_customer({ id: id, ...recipe }, res => {
      setisupdated(true);
      stopLoading();
    })
  }

  const download = () => {
    let prefix = recipe.code.replaceAll(' ', '_');
    let product = recipe.customer_license.product.replaceAll('-', '_');
    startLoading();
    let file_name = prefix + '__' + product + '__lic.lic';
    service.download_lic(id, file_name, { id }, res => {
      stopLoading();
    })
  }

  const generateLic = () => {
    setisupdated(false);
    startLoading();
    service.generate_lic({ id }, res => {
      setisupdated(true);
      stopLoading();
    })
  }

  const created_at = moment(new Date(recipe.created_at_ms));
  let created_at_dt = created_at.format('DD-MMM-YYYY hh:mm A');
  const updated_at = moment(new Date(recipe.updated_at_ms));
  let updated_at_dt = updated_at.format('DD-MMM-YYYY hh:mm A');
  const amc_end_date = moment(new Date(recipe?.customer_license?.amc_end_date));
  let updated_end_dt = amc_end_date.format('DD-MMM-YYYY');

  return (
    <>
      <div className="container">
        <br />
        <h5 className="subtitle is-3 mb-2">
          Customer Details
        </h5>
        <div className="columns">
          <div className="column mt-4">
            <div className="columns">
              <div className="column">
                <article className="message is-light">
                  <div className="message-header">CUSTOMER NAME
                    <div className="tags mb-0 is-capitalized">
                      <span className="tag mb-0 mr-0" style={{ background: "none" }}>Current Status: </span>
                      <span className={`tag mb-0 mr-0 p-0 is-radiusless ${recipe.status_id === "ACTIVE" ? 'is-primary' : 'is-danger'}`} style={{ height: "12px", width: "12px" }}></span>
                      <span className="tag mb-0 pl-1 is-uppercase" style={{ background: "none" }}>{recipe.status_id}</span>
                      <div className="buttons mb-0">
                        <button className={`button is-small mb-0 ${recipe.status_id === "DISABLED" || recipe.status_id === "INACTIVE" ? 'is-success' : 'is-outlined is-danger'}`} type="button" style={{ height: "2.2em" }} onClick={() => { CustomerStatusUpdate() }} >{recipe.status_id === "DISABLED" || recipe.status_id === "INACTIVE" ? "Enable" : "Disable"}</button>

                        {recipe.status_id === 'ACTIVE' && <button className="button is-outlined is-small mb-0 is-info" style={{ height: "2.2em" }}>
                          <span className="icon is-tiny"> <i className="mdi mdi-square-edit-outline line-height-normal"></i> </span>
                          <span onClick={() => { navigate("/dashboard/createcustomer/" + recipe.id) }}>Edit</span>
                        </button>}
                        {recipe.is_lic_generated ?
                          <button className="button is-outlined is-small mb-0 is-info" style={{ height: "2.2em" }}>
                            <span className="icon is-tiny"> <i className="mdi mdi-download-lock-outline line-height-normal"></i> </span>
                            <span onClick={() => download()}>Download lic </span>
                          </button>
                          :
                          <button className="button is-outlined is-small mb-0 is-info" style={{ height: "2.2em" }}>
                            <span className="icon is-tiny"> <i className="mdi mdi-cogs line-height-normal"></i> </span>
                            <span onClick={() => generateLic()}>Generate Lic</span>
                          </button>}
                      </div>
                    </div>
                  </div>
                  <div className="message-body p-0">
                    <table className="table is-striped is-hoverable is-fullwidth" style={{ border: "1px solid rgb(236, 236, 236)" }}>
                      <tbody>
                        <tr>
                          <td style={{ width: "30%" }}>Customer Name</td>
                          <td><b>{recipe.name}</b></td>
                        </tr>
                        <tr>
                          <td style={{ width: "30%" }}>Code</td>
                          <td><b>{recipe.code}</b></td>
                        </tr>
                        <tr>
                          <td style={{ width: "30%" }}>Address Line 1</td>
                          <td><b>{recipe.address1}</b></td>
                        </tr>
                        <tr>
                          <td style={{ width: "30%" }}>Address Line 2</td>
                          <td><b>{recipe.address2}</b></td>
                        </tr>
                        <tr>
                          <td style={{ width: "30%" }}>Address Line 3</td>
                          <td><b>{recipe.address3}</b></td>
                        </tr>
                        <tr>
                          <td>City, State, Country</td>
                          <td><b>{recipe.city}, {recipe.state}, {recipe.country_name}</b></td>
                        </tr>
                        <tr>
                          <td>URL</td>
                          <td><b>{recipe.url}</b></td>
                        </tr>
                        <tr>
                          <td>Created by</td>
                          <td><b>{recipe.created_by_name}</b></td>
                        </tr>
                        <tr>
                          <td>Created On</td>
                          <td><b>{created_at_dt}</b></td>
                        </tr>
                        {recipe.updated_by_name && <tr>
                          <td>Last Updated by</td>
                          <td><b>{recipe.updated_by_name}</b></td>
                        </tr>}
                        {recipe.updated_by_name && <tr>
                          <td>Last Updated on</td>
                          <td><b>{updated_at_dt}</b></td>
                        </tr>}
                      </tbody>
                    </table>
                  </div>
                </article>
              </div>
            </div>
            <div className="columns">
              <div className="column lavel-0">
                <h5 className="subtitle is-4 mb-1">Contacts</h5>
                <div className="field is-horizontal">
                  <div className="field-label is-normal">
                    <label className="label">&nbsp;</label>
                  </div>
                  <div className="field-body">
                    <div className="field">
                      <div className="control">
                        {recipe.customer_contacts && recipe.customer_contacts.filter(item => item.status_id === 'ACTIVE').length > 0 ?
                          <table className="table is-striped is-hoverable is-fullwidth">
                            {readOnly && <thead>
                              <tr>
                                <td style={{ width: '33%' }} >Name</td>
                                <td style={{ width: '33%' }} >Phone Number</td>
                                <td style={{ width: '33%' }} >Email</td>
                              </tr>
                            </thead>}
                            <tbody>
                              {recipe.customer_contacts.filter(item => item.status_id === 'ACTIVE').map((item, index) => {
                                return <tr key={index}>
                                  <td style={{ width: '33%' }} >{item.contact_name}</td>
                                  <td style={{ width: '33%' }} >{item.phone}</td>
                                  <td style={{ width: '33%' }}>{item.email}</td>
                                </tr>
                              })}
                            </tbody>
                          </table> : <div className="blank-div" style={{ padding: '15px', fontSize: '2em' }}>No records available.</div>}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="columns">
              <div className="column">
                <h5 className="subtitle is-4 mb-1">Application Configuration</h5>
                <div className="field is-horizontal pt-1 pb-1">
                  <div className="field-label">
                    <label className="label">Product</label>
                  </div>
                  <div className="field-body">
                    <div className="field is-narrow">
                      <label className="checkbox">
                        <span className="custom-radio">
                          <input type="radio" name="ptype" checked={recipe?.customer_license?.product === 'LIMS' || false} disabled={true} /><span className="radiomark"></span>
                        </span> Lab Iconics LIMS
                      </label>
                    </div>
                    <div className="field is-narrow">
                      <label className="checkbox">
                        <span className="custom-radio">
                          <input type="radio" name="ptype" checked={recipe?.customer_license?.product === 'ELN' || false} disabled={true} /><span className="radiomark"></span>
                        </span> Lab Iconics ELN
                      </label>
                    </div>
                    <div className="field is-narrow">
                      <label className="checkbox">
                        <span className="custom-radio">
                          <input type="radio" name="ptype" checked={recipe?.customer_license?.product === 'LIMS-ELN' || false} disabled={true} /><span className="radiomark"></span>
                        </span> Lab Iconics LIMS-ELN
                      </label>
                    </div>
                  </div>
                </div>
                <div className="field is-horizontal">
                  <div className=" field-label is-normal">
                    <label className="label">No.of User Licenses</label>
                  </div>
                  <div className="field-body">
                    <div className="field is-narrow is-flex-basis-50">
                      <div className="control">
                        <input className="input" placeholder="Enter No.of User Licenses" value={recipe?.customer_license?.users || ''} disabled={true} />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="field is-horizontal pt-1 pb-1">
                  <div className="field-label">
                    <label className="label">Services</label>
                  </div>
                  <div className="field-body">
                    <div className="field is-narrow">
                      <label className="checkbox">
                        <span className="custom-radio">
                          <input type="radio" name="stype" checked={recipe?.customer_license?.subscription === 'Perpetual' || false} disabled={true} /><span className="radiomark"></span>
                        </span> Perpetual
                      </label>
                    </div>
                    <div className="field is-narrow">
                      <label className="checkbox">
                        <span className="custom-radio">
                          <input type="radio" name="stype" checked={recipe?.customer_license?.subscription === 'SaaS' || false} disabled={true} /><span className="radiomark"></span>
                        </span> SaaS
                      </label>
                    </div>
                  </div>
                </div>
                {recipe?.customer_license?.subscription === 'SaaS' && <div className="field is-horizontal">
                  <div className="field-label is-normal">
                    <label className="label">License Expiry Date</label>
                  </div>
                  <div className="field-body">
                    <div className="field is-narrow">
                      <div className="control">
                        <DatePicker
                          placeholderText="License Expiry Date"
                          selected={recipe?.customer_license?.saas_expiry_date ? new Date(recipe?.customer_license?.saas_expiry_date) : ''}
                          className="input"
                          disabled={true}
                          dateFormat="dd-MMM-yyyy"
                          showMonthDropdown={true}
                          selectsStart
                          minDate={new Date()}
                        />
                      </div>
                    </div>
                  </div>
                </div>}

              </div>
            </div>

            <div className="columns">
              <div className="column">
                <h5 className="subtitle is-4 mb-1">AMC</h5>
                <div className="field is-horizontal">
                  <div className="field-label is-normal">
                    <label className="label">Start Date</label>
                  </div>
                  <div className="field-body">
                    <div className="field is-narrow">
                      <div className="control">
                        <DatePicker
                          placeholderText="Start Date"
                          selected={recipe?.customer_license?.amc_start_date ? new Date(recipe?.customer_license?.amc_start_date) : ''}
                          className="input"
                          disabled={true}
                          dateFormat="dd-MMM-yyyy"
                          showMonthDropdown={true}
                          selectsStart
                          minDate={new Date()}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="field is-horizontal">
                  <div className="field-label is-normal">
                    <label className="label">Validity Period</label>
                  </div>
                  <div className="field-body">
                    <div className="field is-narrow">
                      <div className="control">
                        <input className="input" placeholder="Enter Validity Period" maxLength="14" value={recipe?.customer_license?.amc_validity_period || ''} disabled={true} />
                      </div>
                    </div>
                    <div className="field is-narrow">
                      <div className="control">
                        <Select value={recipe?.customer_license?.amc_validity_period_unit || ''}
                          options={
                            [
                              { label: 'Month', value: 'Month' },
                              { label: 'Year', value: 'Year' }
                            ]
                          }
                          menuPlacement="auto" required placeholder="Select" isDisabled={true} />
                      </div>
                    </div>
                  </div>
                </div>
                {recipe?.customer_license?.amc_start_date && recipe?.customer_license?.amc_validity_period && recipe?.customer_license?.amc_validity_period_unit && <div className="field is-horizontal">
                  <div className="field-label is-normal">
                    <label className="label">End Date</label>
                  </div>
                  <div className="field-body">
                    <div className="field is-narrow">
                      <div className="control">
                        <input className="input" disabled={true} value={updated_end_dt || ''} />
                      </div>
                    </div>
                  </div>
                </div>}
              </div>
            </div>

            <div className="columns">
              <div className="column lavel-0">
                <h5 className="subtitle is-4 mb-1">Modules</h5>
                <div className="field is-horizontal">
                  <div className="field-label is-normal">
                    <label className="label">&nbsp;</label>
                  </div>
                  <div className="field-body">
                    <div className="field">
                      <div className="columns m-0 is-flex-wrap-wrap">
                        {modules && recipe.customer_license && modules.filter(m => recipe.customer_license.product === 'LIMS' ? m.is_lims : recipe.customer_license.product === 'ELN' ? m.is_eln : m.is_lims || m.is_eln).map((item, index) => {
                          return <div key={index} className="column is-one-third ">
                            <label className="checkbox">
                              <span className="custom-check">
                                <input type="checkbox" checked={recipe.customer_license.modules.includes(item.id) || false} disabled={true} />
                                <span className="checkmark"></span>
                              </span> {item.menu_name}
                            </label>
                          </div>
                        })}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </>
  )
}

export default ViewCustomer;
